<template>
  <CRow>

    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-map" />
          <h5 class="d-inline ml-2">All Restaurants On Map</h5>
        </CCardHeader>

        <OnMap />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import OnMap from "./OnMap";

export default {
  name: "AllRestaurantsOnMap",
  components: {
    OnMap,
  },
};
</script>

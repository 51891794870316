<template>
  <div>
    <div id="map"></div>
    <div class="sticky table-info px-2 py-3">
      <h5 class="mb-0">Showing {{ total }} restaurants</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnMap",
  data() {
    return {
      restaurantsLocation: [],
      total: 0,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      map: null,
      position: null,

      timeout: []
    };
  },

  beforeDestroy () {
    this.timeout.map(clearTimeout);
  },

  async mounted() {
    await this.getRestaurantsLocation();
    await this.showOnMap();
  },

  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 8,
      });
    },

    async showOnMap() {
      if (!this.map) {
        this.initMap();
      }

      var center_position = { lat: 50.8473094, lng: 4.3383618 };
      this.map.setCenter(center_position);

      var i = 0;
      this.restaurantsLocation.forEach(async (r, index) => {
        if (!r.resto_lat || !r.resto_lng) {
          this.timeout.push(
            window.setTimeout(() => {
              this.getCoordinateFromAddress(r).then((val) => this.position = val);
              this.addMarker(r);
              // console.log("KONUM YOK!");
            }, i++ * 750)
          );
        } else {
          this.position = {
            lat: parseFloat(r.resto_lat),
            lng: parseFloat(r.resto_lng),
          };
          this.addMarker(r);
          // console.log("KONUM VAR.");
        }
      });
    },

    addMarker(restaurant) {
      const contentString = `
        <div class="card" style="width: 12rem;">
          <img class="card-img-top" src="${restaurant.restaurant_logo_path}" alt="${restaurant.restaurant_name}">
          <span class="badge badge-${restaurant.none_active ? "danger" : "success"}">${restaurant.none_active ? "INACTIVE" : "ACTIVE"}</span>
          <div class="card-body">
            <h5 class="card-title">${restaurant.restaurant_name}</h5>
            <p class="card-text">
              ${restaurant.restaurant_address}<br />${restaurant.restaurant_postcode}, <strong>${restaurant.restaurant_city}</strong>
            </p>
            <a href="${this.getRestaurantUrl(restaurant.id)}" target="_blank" class="btn btn-primary">View Restaurant</a>
          </div>
        </div>
      `;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        // maxWidth: 300,
      });

      const marker = new google.maps.Marker({
        map: this.map,
        position: this.position,
        label: restaurant.id.toString(),
        title: restaurant.restaurant_name,
        animation: google.maps.Animation.DROP,
        icon: !restaurant.none_active ? "/img/map/active.png" : "",
      });

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: true,
        });
      });
    },

    async getRestaurantsLocation() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_LOCATIONS)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          this.restaurantsLocation = items;
          this.total = items.length;
        })
        .catch(() => {
          this.restaurantsLocation = [];
        });
    },

    async getCoordinateFromAddress(restaurant) {
      let geocoder = new google.maps.Geocoder();
      let address = `${restaurant.restaurant_address} ${restaurant.restaurant_postcode} ${restaurant.restaurant_city} BE`;
      // var vm = this;

      return new Promise(function (resolve, reject) {
        geocoder.geocode({ address: address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            // vm.verified_address = results[0].formatted_address;
            // vm.form.resto_lat = results[0].geometry.location.lat();
            // vm.form.resto_lng = results[0].geometry.location.lng();
            // vm.isCoordinateUptodate = true;

            resolve({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
            // resolve("Coordinate received");
          } else {
            reject(status);
          }
        });
      });
    },

    getRestaurantUrl(id) {
      return this.$router.resolve({
        name: "View Restaurant",
        params: { id: id },
      }).href;
    },
  },
};
</script>

<style>
#map {
  height: 100%;
  min-height: 640px;
  width: 100%;
}

.gm-style .card {
  box-shadow: none !important;
  margin-bottom: 0;
}
.gm-style .card .card-body {
  padding: 0.5rem;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
</style>